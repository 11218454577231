import { useContext } from "react"

import { AuthContext } from "contexts/AuthContext"

export function useAuthContext() {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('You have to use AuthContextProvider in your App')
  }
    
  return context;
}