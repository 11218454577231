import { useLocaleContext } from "hooks/useLocaleContext"

import pattern1Img from "assets/images/hoodie/thumb-3-pattern-1.png"
import pattern2Img from "assets/images/hoodie/thumb-2-pattern-2.png"

import patternFlipFlopImg from "assets/images/thumb-flipflop.png"
import assortedImg from "assets/images/thumb-assorted.png"

import { Kit } from "types/apiTypes"

import styles from "./Claim.module.scss"

interface ClaimProps {
  kit: Kit
  tokenId: string
}

export function Claim({ kit, tokenId }: ClaimProps) {
  const { localeData, selectedLanguage } = useLocaleContext()

  const { createdAt, benefits, redemptionNumber } = kit

  const formattedDate = new Date(createdAt).toLocaleString(selectedLanguage === 'pt' ? "pt-BR" : "en-US", {
    day: "2-digit", month: "2-digit", year: "numeric"
  })

  const hoodie = benefits.find(benefit => benefit.type === "sweatshirt")
  const hoodieImg = hoodie?.print === 'pattern1' ? pattern1Img : pattern2Img
  const hoodiePattern = hoodie?.print === 'pattern1' ? "1" : "2"

  const flipflop = benefits.find(benefit => benefit.type === "slipper")
  const flipflopImg = flipflop?.benefitImage ? flipflop.benefitImage : patternFlipFlopImg

  return (
    <div className={styles.claim}>
      <div className={styles.claimInfo}>
        <div className={styles.claimInfoItem}>
          <h4>{localeData.claimDetails.claim.number}</h4>
          <span>#{redemptionNumber}</span>
        </div>

        <div className={styles.claimInfoItem}>
          <h4>{localeData.claimDetails.claim.date}</h4>
          <span>{formattedDate}</span>
        </div>

        <div className={styles.claimInfoItem}>
          <h4>{localeData.claimDetails.claim.nft}</h4>
          <span>#{tokenId}</span>
        </div>
      </div>

      <div className={styles.claimResume}>
        <span>{localeData.claimDetails.claim.items}</span>

        <div className={styles.resume}>
          {hoodie && (
            <div className={styles.resumeCard}>
              <div className={styles.resumeCardImg}><img src={hoodieImg} alt={localeData.claimDetails.claim.resume.hoodie} /></div>
        
              <div className={styles.resumeCardInfo}>
                <span>{localeData.claimDetails.claim.resume.hoodie}</span>
                <small>{localeData.claimDetails.claim.resume.size} {hoodie?.size}, {localeData.claimDetails.claim.resume.pattern} {hoodiePattern}</small>
              </div>
            </div>
          )}
          
          {flipflop && (
            <div className={styles.resumeCard}>
              <div className={styles.resumeCardImg}><img src={flipflopImg} alt={localeData.claimDetails.claim.resume.flipflop} /></div>
        
              <div className={styles.resumeCardInfo}>
                <span>{localeData.claimDetails.claim.resume.flipflop}</span>
                <small>{localeData.claimDetails.claim.resume.size} {flipflop.size}, {localeData.claimDetails.claim.resume.custom}</small>
              </div>
            </div>
          )}

          <div className={styles.resumeCard}>
            <div className={styles.resumeCardImg}><img src={assortedImg} alt={localeData.claimDetails.claim.resume.assorted} /></div>
      
            <div className={styles.resumeCardInfo}>
              <span>{localeData.claimDetails.claim.resume.assorted}</span>
            </div>
          </div>
        </div>
      </div>
   </div>
  )
}