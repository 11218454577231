import { ReactComponent as CloseIcon } from "assets/vectors/icon-close-button.svg"
import infoImg from "assets/images/infografico.png"

import { useLocaleContext } from "hooks/useLocaleContext";

import styles from "./Popup.module.scss"

interface PopupProps {
  onCloseModal: () => void;
}

export function Popup({ onCloseModal }: PopupProps) {
  const { localeData } = useLocaleContext()

  return (
    <div className={styles.popupBackdrop}>
      <div className={styles.popupPanel}>
        <div className={styles.popupContent}>
          <div className={styles.popupImage}>
            <img src={infoImg} alt="Holder Benefits" />
          </div>

          <a href={infoImg} target="_blank" rel="noopener noreferrer">
            {localeData.pageBenefits.popup.openImage}
          </a>
        </div>

        <button onClick={onCloseModal} className={styles.popupCloseButton}>
          <CloseIcon />
        </button>
      </div>
    </div>
  )
}