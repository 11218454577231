import React from 'react'
import './SectionStyle.scss'

const Section = ({ data }) => {
  return (
    <section className="section section-galaxy">
      <div className="container">
        <div className="flex-wrapper">
          <div className="left"></div>

          <div className="right">
            <div
              className="right-title appear-fade"
              appear="0.3"
              dangerouslySetInnerHTML={{
                __html: data.galaxy.title,
              }}
            ></div>

            <div className="right-description appear-fade" appear="0.3">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.galaxy.description,
                }}
              ></p>
            </div>
          </div>
        </div>

        <div className="title appear-fade" appear="0.3">
          {data.galaxy.mainTitle}
        </div>
      </div>
    </section>
  )
}

export default Section
