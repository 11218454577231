import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useLocaleContext } from "hooks/useLocaleContext";
import { useUserContext } from "hooks/useUserContext";

import styles from "./MyClaims.module.scss"

export function MyClaims() {
  const { localeData } = useLocaleContext()
  const { claims } = useUserContext()

  const navigate = useNavigate();

  useEffect(() => {
    if(claims.length < 1) {
      navigate("/holder/inventory")
    }
  })

  return (
    <div className={styles.content}>
      <h2>{localeData.myClaims.title}</h2>
      
      <div className={styles.claims}>
        {claims.map(claim => (
          <div className={styles.claimCard} key={claim.id}>
            <h3>Flakkies Kind One Started Kit</h3>

            <div className={styles.claimInfo}>
              {claim.kit.benefits.map(benefit => {
                return (
                  <div key={benefit.type}>
                    {benefit.type === "sweatshirt" ? (
                      <>
                        <strong>{localeData.myClaims.card.hoodie}</strong>
                        <span>{localeData.myClaims.card.size} {benefit.size}, {localeData.myClaims.card.pattern} {benefit.print === 'pattern1' ? "1" : "2"}</span>
                      </>
                    ) : (
                      <>
                        <strong>{localeData.myClaims.card.flipflop}</strong>
                        <span>{localeData.myClaims.card.size} {benefit.size}, {localeData.myClaims.card.custom}</span>
                      </>
                    )}
                  </div>
                )
              })}
            </div>

            <div className={styles.claimActions}>
              <span>#{claim.kit.redemptionNumber}</span>

              <Link to={`${claim.kit.redemptionNumber}`}>{localeData.myClaims.card.button}</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}