import { useState } from 'react'

function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState<string | null>(null)

  async function copyToClipboard(text: string){
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false
    }
  }

  function cleanClipboard() {
    setCopiedText(null)
  }

  return { copiedText, copyToClipboard, cleanClipboard }
}

export default useCopyToClipboard