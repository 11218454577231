import { createContext, ReactNode, useEffect, useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import axios from "axios";

import { api } from "services/api";
import { AuthType, RoleType } from "types/apiTypes";
import { KEY_ROLE, KEY_TOKEN } from "utils/constants";

interface AuthContextType {
  authenticated: boolean
  role: RoleType
  isLoading: boolean
  handleLogin: (callbackSuccess: (url: string) => void, callbackError: () => void) => Promise<void>
  handleLogout: () => void
}

interface AuthContextProviderProps {
  children: ReactNode
}

const initialRoleState = {
  isHolder: false,
  isOnSnapshot: false
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(() => {
    const token = localStorage.getItem(KEY_TOKEN)

    if(token) {
      return true
    }

    return false
  })
  const [role, setRole] = useState<RoleType>(initialRoleState)

  const { address } = useAccount()
  const { disconnect } = useDisconnect()

  useEffect(() => {
    const token = localStorage.getItem(KEY_TOKEN)
    const role = localStorage.getItem(KEY_ROLE)

    if(token) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`
      setAuthenticated(true)

      setIsLoading(false)
    }

    if(role) {
      const parsedRole = JSON.parse(role)
      
      setRole(parsedRole)
    }
  }, [])

  async function handleLogin(callbackSuccess: (url: string) => void, callbackError: () => void) {
    try {
      const { data: { isHolder, isOnSnapshot }, headers } = await api.post<AuthType>(`nfth/${address}`)
      
      const token = headers["access-token"]

      if(!token) {
        throw new Error()
      }

      localStorage.setItem(KEY_TOKEN, JSON.stringify(token))
      localStorage.setItem(KEY_ROLE, JSON.stringify({ isHolder, isOnSnapshot }))

      api.defaults.headers.Authorization = `Bearer ${token}`

      setAuthenticated(true)
      setRole({ isHolder, isOnSnapshot })

      const redirectUrl = isHolder ? "/holder/inventory" : "/holder/benefits"

      callbackSuccess(redirectUrl)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          callbackError()
        }
      }
      
      setRole(initialRoleState)
      disconnect()
    }
  }

  function handleLogout() {
    setAuthenticated(false)
    setRole(initialRoleState)

    localStorage.removeItem(KEY_TOKEN)
    localStorage.removeItem(KEY_ROLE)

    api.defaults.headers.Authorization = null
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        role,
        isLoading,
        handleLogin,
        handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}