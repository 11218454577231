import Lottie from 'lottie-react'
import animation from './animation.json'
import './StarsStyle.scss'

const Stars = () => {
  return (
    <div id="main-stars">
      <Lottie animationData={animation} width={100} height={100} loop={true} />
    </div>
  )
}

export default Stars
