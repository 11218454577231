import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'

import { Router } from 'routes/Router'
import { UserContextProvider } from 'contexts/UserContext'
import { LocaleContextProvider } from 'contexts/LocaleContext'
import { ClaimContextProvider } from 'contexts/ClaimContext'
import { AuthContextProvider } from 'contexts/AuthContext'

import { PROJECT_ID } from 'utils/constants'

const chains = [chain.mainnet]
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: PROJECT_ID }),
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: 'web3Modal', chains }),
  provider,
})

const ethereumClient = new EthereumClient(wagmiClient, chains)

export default function App() {
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <AuthContextProvider>
          <UserContextProvider>
            <LocaleContextProvider>
              <ClaimContextProvider>
                <Router />
              </ClaimContextProvider>
            </LocaleContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </WagmiConfig>

      <Web3Modal
        projectId={PROJECT_ID}
        theme="dark"
        accentColor="default"
        ethereumClient={ethereumClient}
      />
    </>
  )
}
