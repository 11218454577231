import './ButtonStyle.scss'

const Button = ({ children, onClick, ...props }) => {
  return (
    <button type="button" onClick={onClick} {...props} id="main-button">
      <span>{children}</span>
    </button>
  )
}

export default Button
