import React from 'react'
import './PlanetStyle.scss'

import planetImage1 from 'assets/images/page-planet.png'

const Planet = () => {
  return (
    <div id="main-planet">
      <div className="planet-image">
        <img src={planetImage1} alt="Imagem" />
      </div>
    </div>
  )
}

export default Planet
