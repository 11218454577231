import { useState, useCallback, useEffect } from 'react'

import { Card } from "./components/Card";

import { api } from 'services/api';
import { useLocaleContext } from 'hooks/useLocaleContext';

import styles from "./Inventory.module.scss"
import { NftData } from 'types/apiTypes';

export function Inventory() {
  const [nftList, setNftList] = useState<NftData[]>([])
  
  const { localeData } = useLocaleContext()

  const getNFTList = useCallback(async () => {
    try {
      const { data } = await api.get("nfth")

      setNftList(data);
    } catch (error) {
      setNftList([])
    }
  }, [])

  useEffect(() => {
    getNFTList()
  }, [getNFTList])

  return (
    <div className={styles.content}>
      <h1>{localeData.inventory.title}</h1>

      <div className={styles.cards}>
        {nftList.length > 0 && nftList.map(nft => (
          <Card
            key={nft.image}
            nftData={nft}
          />
        ))}
      </div>
    </div>
  );
}