import './HomeStyle.scss'

import SectionCards from 'components/pages/home/SectionCards'
import SectionContact from 'components/pages/home/SectionContact'
import SectionFlakkies from 'components/pages/home/SectionFlakkies'
import SectionGalaxy from 'components/pages/home/SectionGalaxy'
import SectionHero from 'components/pages/home/SectionHero'

const HomeContainer = ({ data }) => {
  return (
    <main id="page-home" className="page">
      <SectionHero data={data} />
      <SectionCards data={data} />
      <SectionGalaxy data={data} />
      <SectionFlakkies data={data} />
      <SectionContact data={data} />
    </main>
  )
}

export default HomeContainer
