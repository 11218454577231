import { ReactElement } from "react"
import { Navigate } from "react-router-dom";

import { useAuthContext } from "hooks/useAuthContext";
interface PrivateRouteProps {
  children: ReactElement
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  const { authenticated } = useAuthContext()

  if(!authenticated) {
    return <Navigate to="/" />
  }

  return children
}