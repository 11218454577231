import { useEffect, useState } from "react"

import styles from "./CardInput.module.scss"

interface CardInputProps {
  flakkie: {
    imageUrl: string
    id: string
    planet: string | undefined
    name: string | undefined
  },
  hasLocaleFile: boolean,
  onSelectFlakkie: (url: string, id: string) => void
}

export function CardInput({ flakkie, onSelectFlakkie, hasLocaleFile }: CardInputProps) {
  const [profileImageValue, setProfileImageValue] = useState(false)

  function handleOnChange() {
    setProfileImageValue(oldValue => !oldValue)

    onSelectFlakkie(flakkie.imageUrl, flakkie.id)
  }

  useEffect(() => {
    if(hasLocaleFile) {
      setProfileImageValue(false)
    }
  }, [hasLocaleFile])

  return (
    <div className={styles.cardInput}>
      <input 
        type="radio" 
        name="profileImage" 
        id={flakkie.id}
        onChange={handleOnChange}
        checked={profileImageValue}
        hidden
      />

      <label htmlFor={flakkie.id} className={styles.label}>
        <div className={styles.cardInfo}>
          <div className={styles.cardSelectImage}>
            <img src={flakkie.imageUrl} alt={flakkie.name} data-id={flakkie.id} crossOrigin="anonymous" />
          </div>

          <div>
            <span>{flakkie.name}</span>
            <small>{flakkie.planet}</small>
          </div>
        </div>
      </label>
    </div>
  )
}