export async function transformImageToBlob(id: string) {
  let img = document.querySelector(`img[data-id="${id}"]`) as HTMLImageElement;

  if(!img) return;

  let canvas = document.createElement('canvas');
  canvas.width = 3240;
  canvas.height = 3240;

  let context = canvas.getContext('2d');

  if(!context) return;

  context.drawImage(img, 0, 0);

  let blob = await new Promise((resolve: (blob: Blob | null) => void, reject) => {
    canvas.toBlob(resolve, 'image/png')
  })

  return blob;
}