import { useContext, useEffect, useState } from 'react';
import { useAccount } from 'wagmi'

import { ImagePreview } from "../ImagePreview";
import { Toast } from 'components/general/Toast';

import useCopyToClipboard from 'utils/useCopyToClipboard';
import { LocaleData } from 'types/language';
import { api } from 'services/api';
import { UserContext } from 'contexts/UserContext';

import styles from "./EditForm.module.scss"

interface EditFormProps {
  localeData: LocaleData
}

export function EditForm({ localeData }: EditFormProps) {
  const { address } = useAccount()
  const { copiedText, copyToClipboard, cleanClipboard } = useCopyToClipboard()
  const { invalidateData } = useContext(UserContext)

  const [username, setUsername] = useState("");
  const [toastVisibility, setToastVisibility] = useState<"visible" | "hidden">("hidden")
  const [toastInfo, setToastInfo] = useState({
    text: "",
    isError: false
  })

  function handleToastVisibility(text: string, isError: boolean) {
    setToastVisibility("visible")

    setToastInfo({
      text,
      isError
    })
  }

  async function handleSaveProfile() {
    try {
      const data = {
        name: username
      }
  
      await api.patch("users", data)

      handleToastVisibility(localeData.profile.toast.profileSuccess, false)
    } catch (error) {
      handleToastVisibility(localeData.profile.toast.profileError, true)
    } finally {
      invalidateData()
    }
  }

  async function handleCopyToClipboard() {
    if(address) {
      await copyToClipboard(address)
    }
  }

  useEffect(() => {
    const clipboard = setTimeout(() => {
      cleanClipboard()
    }, 3000);

    return () => clearTimeout(clipboard)
  }, [copiedText, cleanClipboard]) 

  const buttonIsDisabled = username.length <= 0 ? true : false

  return (
    <div className={styles.editForm}>
      <ImagePreview
        localeData={localeData}
      />

      <div className={styles.form}>
        <div className={styles.boxInput}>
          <label htmlFor="username">
            {localeData.profile.inputName}
          </label>
          <input 
            type="text" 
            id="username" 
            placeholder={localeData.profile.placeholderName}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        <div className={styles.boxCopy}>
          <span>{localeData.profile.copyWalletAddress}</span>

          <button type='button' onClick={handleCopyToClipboard}>
            <span>{address}</span>

            <div className={styles.copyIcon}>
              {copiedText && (
                <div className={styles.tooltip}>
                  <span>{localeData.profile.copyWalletAddressTooltip}</span>
                </div>
              )}
              
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M8.64388 1.64384C9.13616 1.15156 9.80384 0.875 10.5 0.875H15.185C15.8709 0.875085 16.5296 1.14361 17.02 1.62312L20.3349 4.86505C20.585 5.10949 20.7837 5.40144 20.9194 5.72374C21.0551 6.04605 21.125 6.39222 21.125 6.74192V15.5C21.125 16.1962 20.8485 16.8639 20.3562 17.3562C19.8639 17.8484 19.1962 18.125 18.5 18.125H17.125V19.5002C17.125 20.1964 16.8484 20.8641 16.3562 21.3564C15.8639 21.8487 15.1962 22.1252 14.5 22.1252H6.5C5.80381 22.1252 5.13613 21.8487 4.64384 21.3564C4.15156 20.8641 3.875 20.1964 3.875 19.5002V8.50024C3.875 7.80405 4.15156 7.13637 4.64384 6.64409C5.13613 6.15181 5.80381 5.87524 6.5 5.87524H7.87503V3.5C7.87503 2.80381 8.15159 2.13613 8.64388 1.64384ZM7.87503 7.12524H6.5C6.13533 7.12524 5.78559 7.27011 5.52773 7.52797C5.26987 7.78583 5.125 8.13557 5.125 8.50024V19.5002C5.125 19.8649 5.26987 20.2147 5.52773 20.4725C5.78559 20.7304 6.13533 20.8752 6.5 20.8752H14.5C14.8647 20.8752 15.2144 20.7304 15.4723 20.4725C15.7301 20.2147 15.875 19.8649 15.875 19.5002V18.125H10.5C9.80384 18.125 9.13616 17.8484 8.64388 17.3562C8.15159 16.8639 7.87503 16.1962 7.87503 15.5V7.12524ZM10.5 2.125C10.1354 2.125 9.78562 2.26987 9.52776 2.52773C9.2699 2.78559 9.12503 3.13533 9.12503 3.5V15.5C9.12503 15.8647 9.2699 16.2144 9.52776 16.4723C9.78562 16.7301 10.1354 16.875 10.5 16.875H18.5C18.8647 16.875 19.2144 16.7301 19.4723 16.4723C19.7302 16.2144 19.875 15.8647 19.875 15.5V6.74208C19.875 6.74205 19.875 6.7421 19.875 6.74208C19.875 6.55892 19.8384 6.37755 19.7673 6.20874C19.6962 6.03992 19.5921 5.88699 19.4612 5.75895L16.1461 2.51687C16.1461 2.51686 16.1461 2.51689 16.1461 2.51687C15.8892 2.26575 15.5443 2.12507 15.185 2.125C15.185 2.125 15.1851 2.125 15.185 2.125H10.5Z" fill="#D2D2D2"/>
              </svg>
            </div>
          </button>
        </div>
      </div>

      <button type="button" className={styles.submitButton} disabled={buttonIsDisabled} onClick={handleSaveProfile}>
        {localeData.profile.buttonSubmit}
      </button>

      <Toast 
        setVisibility={setToastVisibility}
        visibility={toastVisibility}
        text={toastInfo.text}
        isError={toastInfo.isError}
      />
    </div>
  )
}