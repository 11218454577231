import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isPast } from "date-fns";

import { useLocaleContext } from "hooks/useLocaleContext";
import { useAuthContext } from "hooks/useAuthContext";

import { ReactComponent as IconBag } from "assets/vectors/icon-bag.svg";
import { ReactComponent as IconClockBold } from "assets/vectors/icon-clock-bold.svg";

import cardHoodieImg from "assets/images/card-hoodie.png";
import cardFlipFlopImg from "assets/images/card-flip-flop.png";
import cardSurpriseImg from "assets/images/card-surprise.png";
import cardDiscordImg from "assets/images/card-discord.png";
import cardWorkshopImg from "assets/images/card-workshop.png";

import { Popup } from "./components/Popup";

import styles from "./Benefits.module.scss"
import { CLAIM_EXPIRES_DATE, CLAIM_START_DATE } from "utils/constants";
import { Snapshot } from "types/apiTypes";
import { api } from "services/api";

export function Benefits() {
  const { localeData } = useLocaleContext()
  const { role } = useAuthContext()
  const navigate = useNavigate();

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [snapshots, setSnapshots] = useState<Snapshot[]>([])

  function openPopup() {
    setIsPopupOpen(true)
  }

  function closePopup() {
    setIsPopupOpen(false)
  }

  function navigateToClaim() {
    navigate("kit")
  }

  const getSnapshots = useCallback(async () => {
    try {
      const { data } = await api.get<Snapshot[]>("/nfth/snapshots",)

      setSnapshots(data);
    } catch (error) {
      setSnapshots([])
    }
  }, [])

  useEffect(() => {
    getSnapshots()
  }, [getSnapshots])

  const claimExpiresDate = new Date(CLAIM_EXPIRES_DATE).getTime()
  const claimStartDate = new Date(CLAIM_START_DATE).getTime()
  const isExpired = isPast(claimExpiresDate)
  const isStarted = isPast(claimStartDate)

  const claimsAvailable = snapshots.filter(snapshot => snapshot.burned === false).length
  const claimsAvailableText = claimsAvailable === 1 ? localeData.pageBenefits.physicalBenefits.card.availableKit :  localeData.pageBenefits.physicalBenefits.card.availableKits

  return (
    <div className={styles.content}>
      {role.isOnSnapshot && (
        <section className={styles.physicalBenefitsSection}>
          <div className={styles.header}>
            <h2>{localeData.pageBenefits.physicalBenefits.title}</h2>
            <span>
              {localeData.pageBenefits.physicalBenefits.subtitle} 
              <button onClick={openPopup}>{localeData.pageBenefits.physicalBenefits.triggerPopup}</button>
            </span>
          </div>

          <article className={styles.benefit}>
            <div className={styles.products}>
              <div className={styles.productCard}>
                <img src={cardHoodieImg} alt={localeData.pageBenefits.physicalBenefits.card.product1} />
                <span>{localeData.pageBenefits.physicalBenefits.card.product1}</span>
              </div>

              <small>+</small>

              <div className={styles.productCard}>
                <img src={cardFlipFlopImg} alt={localeData.pageBenefits.physicalBenefits.card.product2} />
                <span>{localeData.pageBenefits.physicalBenefits.card.product2}</span>
              </div>

              <small>+</small>

              <div className={styles.productCard}>
                <img src={cardSurpriseImg} alt={localeData.pageBenefits.physicalBenefits.card.product3} />
                <span>{localeData.pageBenefits.physicalBenefits.card.product3}</span>
              </div>
            </div>

            <h3>{localeData.pageBenefits.physicalBenefits.card.title}</h3>

            {isStarted && (
              <div className={styles.claimInfos}>
                <div>
                  <IconClockBold />
                  {isExpired ? (
                    <span>{localeData.pageBenefits.physicalBenefits.card.claimExpiredDate}</span>
                  ) : (
                    <span>{localeData.pageBenefits.physicalBenefits.card.claimDate}</span>
                  )}
                </div>

                {!isExpired && (
                  <div>
                    <IconBag />
                    {claimsAvailable > 0 ? (
                      <span>{claimsAvailable} {claimsAvailableText}</span>
                    ) : (
                      <span>{localeData.pageBenefits.physicalBenefits.card.unavailableKits}</span>
                    )}
                  </div>
                )}
              </div>
            )}

            {!isExpired && claimsAvailable > 0 && (
              <button disabled={!isStarted} onClick={navigateToClaim}>
                {isStarted ? localeData.pageBenefits.physicalBenefits.card.textButton : localeData.pageBenefits.physicalBenefits.card.disableTextButton} 
              </button>
            )}
          </article>
        </section>
      )}

      {role.isHolder && (
        <section className={styles.othersBenefitsSection}>
          <div className={styles.header}>
            <h2>{localeData.pageBenefits.othersBenefits.title}</h2>
          </div>

          <div className={styles.content}>
            <article className={styles.benefit}>
              <div className={styles.benefitImage}>
                <img src={cardDiscordImg} alt={localeData.pageBenefits.othersBenefits.card1.title} />
              </div>

              <div className={styles.benefitInfo}>
                <h3>{localeData.pageBenefits.othersBenefits.card1.title}</h3>
                <span>{localeData.pageBenefits.othersBenefits.card1.subtitle}</span>

                <a href="https://discord.gg/havaianas" target="_blank" rel="noopener noreferrer">
                  {localeData.pageBenefits.othersBenefits.card1.textButton}
                </a>
              </div>
            </article>

            <article className={styles.benefit}>
              <div className={styles.benefitImage}>
                <img src={cardWorkshopImg} alt={localeData.pageBenefits.othersBenefits.card2.title} />
              </div>

              <div className={styles.benefitInfo}>
                <h3>{localeData.pageBenefits.othersBenefits.card2.title}</h3>
                <span>{localeData.pageBenefits.othersBenefits.card2.subtitle}</span>

                <button disabled>
                  {localeData.pageBenefits.othersBenefits.card2.textButton}
                </button>
              </div>
            </article>
          </div>
        </section>
      )}

      {isPopupOpen && (
        <Popup onCloseModal={closePopup} />
      )}
    </div>
  )
}