import { isMobile } from 'react-device-detect'
import './SectionStyle.scss'

import FlakkieCard1 from 'assets/images/cards/flakkie-card-1.png'
import FlakkieCard2 from 'assets/images/cards/flakkie-card-2.png'
import FlakkieCard3 from 'assets/images/cards/flakkie-card-3.png'
import FlakkieCard4 from 'assets/images/cards/flakkie-card-4.png'
import FlakkieCard5 from 'assets/images/cards/flakkie-card-5.png'
import FlakkieCard6 from 'assets/images/cards/flakkie-card-6.png'
import FlakkieCard7 from 'assets/images/cards/flakkie-card-7.png'
import FlakkieCard8 from 'assets/images/cards/flakkie-card-8.png'

import FlakkieCard10 from 'assets/images/cards/flakkie-card-10.png'
import FlakkieCard11 from 'assets/images/cards/flakkie-card-11.png'
import FlakkieCard12 from 'assets/images/cards/flakkie-card-12.png'
import FlakkieCard13 from 'assets/images/cards/flakkie-card-13.png'
import FlakkieCard14 from 'assets/images/cards/flakkie-card-14.png'
import FlakkieCard15 from 'assets/images/cards/flakkie-card-15.png'
import FlakkieCard16 from 'assets/images/cards/flakkie-card-16.png'
import FlakkieCard9 from 'assets/images/cards/flakkie-card-9.png'

const Section = ({ data }) => {
  return (
    <section className="section section-cards">
      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div className="left-title appear-fade" appear="0.1">
              {data.cards.title}
            </div>
          </div>

          <div className="right">
            <div
              className="right-description appear-fade"
              appear="0.1"
              apepar-delay="100"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: data.cards.description,
                }}
              ></p>
            </div>
          </div>
        </div>

        <div className="cards" parallax={isMobile ? undefined : '1.5'}>
          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard1} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard2} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard3} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard4} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard5} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard6} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard7} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard8} alt="Imagem" />
          </div>
        </div>

        <div className="mini-cards" parallax="1.5" positive="true">
          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard9} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard10} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard11} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard12} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard13} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard14} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard15} alt="Imagem" />
          </div>

          <div className="card appear-fade" appear="0.2">
            <img src={FlakkieCard16} alt="Imagem" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
