import { useContext } from "react"
import { Link, NavLink } from "react-router-dom"
import { useDisconnect, useAccount } from 'wagmi'

import { UserContext } from "contexts/UserContext"
import { useLocaleContext } from "hooks/useLocaleContext"
import { useAuthContext } from "hooks/useAuthContext"
import { useUserContext } from "hooks/useUserContext"

import { formatWalletId } from "utils/formatedWalletId"

import avatarImg from "assets/images/avatar.png"
import avatarNotHolderImg from "assets/images/avatar-not-holder.png"
import { ReactComponent as InventoryIcon } from "assets/vectors/icon-inventory.svg"
import { ReactComponent as BenefitsIcon } from "assets/vectors/icon-benefits.svg"
import { ReactComponent as MyClaimsIcon } from "assets/vectors/icon-claims.svg"

import styles from "./Menu.module.scss"

interface MenuProps {
  isMenuMobileExpanded: boolean;
}

export function Menu({ isMenuMobileExpanded }: MenuProps) {
  const { profileImage, name } =  useContext(UserContext)
  const { localeData } =  useLocaleContext()
  const { handleLogout, role } = useAuthContext()
  const { claims } = useUserContext()

  const menuClasses = isMenuMobileExpanded ? `
    ${styles.menu} ${styles.menuExpanded}
  ` : styles.menu

  const { disconnect } = useDisconnect()

  function disconnectToWallet() {
    handleLogout()
    disconnect()
  }

  const { address } = useAccount()

  const imageUrl = profileImage ? profileImage : avatarImg
  const image = !role.isHolder ? avatarNotHolderImg : imageUrl
  
  const formattedWalletId = address && formatWalletId(address)
  const username = !name || !role.isHolder ? formattedWalletId?.toUpperCase() : name

  const hasClaims = claims.length >= 1 ? true : false

  return (
    <aside className={menuClasses}>
      <div className={styles.profile}>
        <div className={styles.profileImg}>
          <img src={image} alt="Avatar" />
        </div>

        <div>
          <span>{username}</span>
          
          {role.isHolder && <Link to="/holder/profile">{localeData.inventory.menu.profile.editProfile}</Link>}
        </div>
      </div>

      <nav className={styles.navbar}>
        {role.isHolder && (
          <NavLink to="/holder/inventory" title={localeData.inventory.menu.navLink1}>
            <InventoryIcon />
            {localeData.inventory.menu.navLink1}
          </NavLink>
        )}

        <NavLink to="/holder/benefits" title={localeData.inventory.menu.navLink2}>
          <BenefitsIcon />
          {localeData.inventory.menu.navLink2}
        </NavLink>

        {hasClaims && (
           <NavLink to="/holder/my-claims" title={localeData.inventory.menu.navLink3}>
            <MyClaimsIcon />
            {localeData.inventory.menu.navLink3}
          </NavLink>
        )}
      </nav>

      <button type="button" className={styles.logoutButton} onClick={disconnectToWallet}>
        {localeData.inventory.menu.logout}
      </button>
    </aside>
  )
}