import React from 'react'
import ReactDOM from 'react-dom/client'
import { WagmiConfig, createClient } from 'wagmi'
import { providers } from 'ethers'

import './sass/main.scss'

import App from './App'

const client = createClient({
  autoConnect: true,
  provider: new providers.AlchemyProvider(
    'homestead',
    'N4JBVRGPYazXn55N7EncpGjDr0brq0Aa',
  ),
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <WagmiConfig client={client}>
    <App />
  </WagmiConfig>,
)

