import { useContext, useState } from "react"

import { ReactComponent as CloseIcon } from "assets/vectors/icon-close-white.svg"
import avatarImg from "assets/images/avatar-holder-profile.png"

import { CardInput } from "../CardInput"
import { Spinner } from "components/general/Spinner"

import { LocaleData } from "types/language"
import { NftData } from "types/apiTypes"

import { api } from "services/api"
import { UserContext } from "contexts/UserContext"
import { transformImageToBlob } from "utils/transformImageToBlob"

import styles from "./EditModal.module.scss"

interface ModalProps {
  localeData: LocaleData
  nftList: NftData[] | []
  onCloseModal: () => void
  showToast: (text: string, isError: boolean) => void
}

export function EditModal({ onCloseModal, localeData, nftList, showToast }: ModalProps) {
  const { profileImage, invalidateData } = useContext(UserContext)

  const [localeImageUrl, setLocaleImageUrl] = useState("")
  const [hasLocaleFile, setHasLocaleFile] = useState(false)
  const [imageFile, setImageFile] = useState({} as File)

  const [isLoading, setIsLoading] = useState(false);
  
  function handleImageUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if(!e.target.files?.[0]) {
      return;
    }

    setHasLocaleFile(true)
    setImageFile(e.target.files[0])

    const imageUrl = URL.createObjectURL(e.target.files[0])
    setLocaleImageUrl(imageUrl)
  }

  async function onSelectFlakkie(url: string, id: string) {
    const blob = await transformImageToBlob(id)

    if(!blob) return
    
    const filename = `${id}.png`

    const file = new File([blob], filename, { type: blob.type })

    setHasLocaleFile(false)
    setLocaleImageUrl(url)
    setImageFile(file)
  }

  async function onSaveProfilePicture() {
    setIsLoading(true)

    const formData = new FormData()
    formData.append("profileImage", imageFile, imageFile.name)

    try {
      await removeOldImage()

      await api.post("users/images", formData, {
        headers: {
          "Content-Type":'multipart/form-data'
        }
      })

      showToast(localeData.profile.toast.imageSuccess, false)
      onCloseModal()
    } catch (error) {
      showToast(localeData.profile.toast.imageError, true)
      onCloseModal()
    } finally {
      invalidateData()
      setIsLoading(false)
    }
  }

  async function removeOldImage() {
    if(profileImage) {
      await api.delete("users/images")
    }
  }

  const flakkies = nftList?.map(nft => {
    const id = nft.name?.replace(/\s/g, '-').toLowerCase()
    
    const planetAttr = nft.attributes.find(attr => attr.traitType === "Planet")
    const flakkieAttr = nft.attributes.find(attr => attr.traitType === "Flakkie")

    return {
      imageUrl: nft.image,
      id,
      planet: planetAttr?.value,
      name: flakkieAttr?.value,
    }
  })

  const imageUrlFallback = profileImage.length > 0 ? profileImage : avatarImg
  const saveButtonIsDisabled = imageFile.name ? false : true

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalPanel}>
        <header>
          <h3>{localeData.profile.modal.title}</h3>

          <button type="button" onClick={onCloseModal}>
            <CloseIcon />
          </button>
        </header>
        
        <div className={styles.modalContent}>
          <span>{localeData.profile.modal.subtitle}</span>

          <div className={styles.imageUpload}>
            <label htmlFor="imagePreview">
              {localeImageUrl.length > 0 ? (
                <img src={localeImageUrl} alt="" />
              ) : (
                <img src={imageUrlFallback} alt="" />
              )}
            </label>
            
            <input type="file" accept="image/*" id="imagePreview" hidden onChange={handleImageUpload} />
          </div>

            <div className={styles.imageList}>
              {flakkies?.map(flakkie => (
                <CardInput 
                  flakkie={flakkie}
                  onSelectFlakkie={onSelectFlakkie}
                  hasLocaleFile={hasLocaleFile}
                  key={flakkie.id}
                />
              ))}
            </div>

          <button type="button" onClick={onSaveProfilePicture} disabled={saveButtonIsDisabled}>
            {isLoading ? <Spinner /> : localeData.profile.modal.buttonText}
          </button>
        </div>
      </div>
    </div>
  )
}