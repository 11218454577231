// import { isMobile } from 'react-device-detect'
import './SectionStyle.scss'

import BlueBlur from 'assets/images/background/blue_blur.png'
import GreenBlur from 'assets/images/background/green_blur.png'
import FlakkiesCardBlur from 'assets/images/flakkies-card-blur.png'
import FlakkiesCard from 'assets/images/new-flakkie-cards.png'

import Clouds from 'components/general/Clouds'

const Section = ({ data }) => {
  return (
    <section className="section section-hero">
      <div className="clouds">
        <div className="cloud">
          <Clouds />
        </div>
        <div className="clouds-upside-down">
          <Clouds />
        </div>
      </div>

      <div className="container">
        <div className="flex-wrapper">
          <div className="green-blur">
            <img src={GreenBlur} alt="green blur" />
          </div>
          <div className="blue-blur">
            <img src={BlueBlur} alt="blue blur" />
          </div>
          <div className="wrapper">
            <div className="wrapper-title appear-fade" appear="0">
              {data.hero.title}
            </div>

            <div
              className="wrapper-description appear-fade"
              appear="0"
              appear-delay="100"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: data.hero.description,
                }}
              ></p>
            </div>
          </div>

          <div className="galaxy" appear="0">
            <img src={FlakkiesCard} alt="Card Flakkie" />
            <img
              className="card-blur"
              src={FlakkiesCardBlur}
              alt="Card Flakkie"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
