import { useLocaleContext } from "hooks/useLocaleContext"
import { NftData } from "types/apiTypes"

import styles from "./Card.module.scss"

interface CardProps {
  nftData: NftData
}

export function Card({ nftData }: CardProps) {
  const { localeData } = useLocaleContext()

  const name = nftData?.attributes?.find(attr => attr.traitType === "Flakkie")?.value
  const planet = nftData?.attributes?.find(attr => attr.traitType === "Planet")?.value

  const rarityPlanets = {
    "Kosmik": localeData.flakkiesRarity.Kosmik,
    "Palpet": localeData.flakkiesRarity.Palpet,
    "Rebel-D": localeData.flakkiesRarity.RebelD,
    "Epik": localeData.flakkiesRarity.Epik,
  }

  type planetKey = keyof typeof rarityPlanets

  const currentPlanet = planet as planetKey
  const rarity = rarityPlanets?.[currentPlanet]

  const splitedURL = nftData.image.split("/")
  const id = splitedURL[splitedURL.length - 1]

  const nftId = `${id.slice(0, 6)}...${id.slice(id.length - 4, id.length)}`

  return (
    <div className={styles.flakkiesCard}>
      <div className={styles.image}>
        <img src={nftData.image} alt="Jelly" />
      </div>

      <div className={styles.identification}>
        <div>
          <h2>{name}</h2>

          <a href={nftData.image} target="_blank" rel="noopener noreferrer">
            {nftId}
          </a>
        </div>

        <div>
          <span>{planet}</span>

          <span>{rarity}</span>
        </div>
      </div>
    </div>
  )
}