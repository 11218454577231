import { isMobile } from 'react-device-detect'
import './FlakkiesStyle.scss'

import PlanetImage1 from 'assets/images/flakkies-planet-1.png'
import PlanetImage2 from 'assets/images/flakkies-planet-2.png'
import PlanetImage3 from 'assets/images/flakkies-planet-3.png'
import PlanetImage4 from 'assets/images/flakkies-planet-4.png'
import Rocket from 'assets/images/flakkies-rocket.png'

const Flakkies = ({ data }) => {
  return (
    <div id="main-flakkies">
      {!isMobile && (
        <div className="rocket">
          <img src={Rocket} alt="Imagem" />
        </div>
      )}

      <div className="flex-wrapper">
        <div className="item">
          <div className="item-left">
            <div className="item-left--rarity">
              {data.flakkies.card1.rarity}
            </div>

            <div
              className="item-left--title"
              dangerouslySetInnerHTML={{
                __html: data.flakkies.card1.title,
              }}
            ></div>

            <div className="item-left--description">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.flakkies.card1.description,
                }}
              ></p>
            </div>
          </div>

          <div className="item-right">
            <div className="item-right--title">Kosmik</div>

            <div className="item-right--planet">
              <div className="item-right--planet_image">
                <img src={PlanetImage1} alt="Imagem" />
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="item-left">
            <div className="item-left--rarity">
              {data.flakkies.card2.rarity}
            </div>

            <div
              className="item-left--title"
              dangerouslySetInnerHTML={{
                __html: data.flakkies.card2.title,
              }}
            ></div>

            <div className="item-left--description">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.flakkies.card2.description,
                }}
              ></p>
            </div>
          </div>

          <div className="item-right">
            <div className="item-right--title">Palpet</div>

            <div className="item-right--planet">
              <div className="item-right--planet_image">
                <img src={PlanetImage2} alt="Imagem" />
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="item-left">
            <div className="item-left--rarity">
              {data.flakkies.card3.rarity}
            </div>

            <div
              className="item-left--title"
              dangerouslySetInnerHTML={{
                __html: data.flakkies.card3.title,
              }}
            ></div>

            <div className="item-left--description">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.flakkies.card3.description,
                }}
              ></p>
            </div>
          </div>

          <div className="item-right">
            <div className="item-right--title">Rebel-D</div>

            <div className="item-right--planet">
              <div className="item-right--planet_image">
                <img src={PlanetImage3} alt="Imagem" />
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="item-left">
            <div className="item-left--rarity">
              {data.flakkies.card4.rarity}
            </div>

            <div
              className="item-left--title"
              dangerouslySetInnerHTML={{
                __html: data.flakkies.card4.title,
              }}
            ></div>

            <div className="item-left--description">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.flakkies.card4.description,
                }}
              ></p>
            </div>
          </div>

          <div className="item-right">
            <div className="item-right--title">Epik</div>

            <div className="item-right--planet">
              <div className="item-right--planet_image">
                <img src={PlanetImage4} alt="Imagem" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Flakkies
