import { Fragment } from "react";

import { Link, useLocation } from "react-router-dom";

import { PathType } from "types/language";
import { useLocaleContext } from "hooks/useLocaleContext";

import { ReactComponent as IconChevronRight } from "assets/vectors/icon-chevron-right.svg";

import styles from "./Breadcrumb.module.scss"

export function Breadcrumb() {
  const { localeData } = useLocaleContext()
  const { pathname } = useLocation()

  const currentPaths = pathname.split("/").slice(2) as PathType[]

  return (
    <div className={styles.breadcrumbContainer}>
      <Link to="/holder/inventory">
        {localeData.holderBreadcrumb.holderLink}
      </Link>

      {currentPaths.map((path, index, paths) => (
        <Fragment key={index}>
          <IconChevronRight />
          
          {index === currentPaths.length - 1 ? (
            <span>{localeData.holderBreadcrumb.paths?.[path] || path}</span>
          ) : (
            <Link to={`/holder/${paths.slice(0, index + 1).join("/")}`}>
              {localeData.holderBreadcrumb.paths[path]}
            </Link>
          )}
        </Fragment>
      ))}
    </div>
  )
}