import { useEffect } from "react";

import { ReactComponent as IconSuccess } from "assets/vectors/icon-check-filled.svg"
import { ReactComponent as IconError } from "assets/vectors/icon-close-square.svg"

import styles from "./Toast.module.scss"

interface ToastProps {
  isError?: boolean;
  visibility: "visible" | 'hidden'
  setVisibility: (visibility: "visible" | 'hidden') =>void
  text: string
}

export function Toast({ isError, text, visibility, setVisibility }: ToastProps) {
  const toastClass = visibility === "visible" ? `${styles.toast} ${styles.toastVisible}` : styles.toast

  useEffect(() => {
    if(visibility === "visible") {
      const toast = setTimeout(() => {
        setVisibility("hidden")
      }, 3000);
  
      return () => clearTimeout(toast)
    }
  }, [visibility, setVisibility]) 


  if(isError) {
    return (
      <div className={toastClass}>
        <div className={styles.content}>
          <div className="icon">
            <IconError />
          </div>
    
          <span>{text}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={toastClass}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <IconSuccess />
        </div>

        <span>{text}</span>
      </div>
    </div>
  )
}