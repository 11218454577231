import { useEffect, useState } from 'react'
import languageEN from 'utils/language-en'
import languagePT from 'utils/language-pt'

import Appear from 'utils/appear'
import ScrollPage from 'utils/scroll-page'

import Flakkies from 'components/general/Flakkies'
import Header from 'components/general/Header'
import Planet from 'components/general/Planet'
import Preloader from 'components/general/Preloader'

import Stars from 'components/general/Stars'
import HomeContainer from 'container/home'

const Home = () => {
  const [language, setLanguage] = useState('pt')

  useEffect(() => {
    const lang = window.navigator.userLanguage || window.navigator.language

    if (lang !== 'pt-BR') setLanguage('en')
  }, [])

  return (
    <>
      <ScrollPage />
      <Appear />
      <Stars />

      <Header
        data={language === 'pt' ? languagePT : languageEN}
        language={language}
        setLanguage={setLanguage}
      />
      <Flakkies data={language === 'pt' ? languagePT : languageEN} />
      <Planet />
      <Preloader />
      <HomeContainer data={language === 'pt' ? languagePT : languageEN} />
    </>
  )
}

export default Home
