import React from 'react'
import './SectionStyle.scss'

const Section = ({ data }) => {
  return (
    <section className="section section-flakkies">
      <div className="container"></div>
    </section>
  )
}

export default Section
