import { useLocaleContext } from "hooks/useLocaleContext"
import { Checkout } from "types/apiTypes"

import styles from "./Shipping.module.scss"

interface ShippingProps {
  checkout: Checkout
}

export function Shipping({ checkout }: ShippingProps) {
  const { selectedLanguage } = useLocaleContext()

  const formattedPostalCode = selectedLanguage === 'pt' ? checkout.postalCode.replace(/^([\d]{5})-*([\d]{3})/, '$1-$2') : checkout.postalCode
  const fullAddress = `${checkout.address}, ${checkout.city} ${checkout.state}, ${checkout.country} ${formattedPostalCode}`

  return (
    <div className={styles.shipping}>
      <strong>{checkout.fullName}</strong>
      <span>{checkout.email}</span>
      <span>{fullAddress}</span>
    </div>
  )
}