import React, { useEffect } from 'react'
import Lottie from 'lottie-react'
import animation from './animation.json'
import './PreloaderStyle.scss'

const Preloader = () => {
  useEffect(() => {
    // const preloader = document.querySelector('#main-preloader')
    const body = document.querySelector('body')

    // setTimeout(() => preloader.classList.add('animate'), 100)

    // setTimeout(() => {
    //   preloader.classList.add('remove')
    //   body.dispatchEvent(new Event('finishedPreloader'))
    // }, 4200)
    body.dispatchEvent(new Event('finishedPreloader'))
  }, [])

  return (
    <div id="main-preloader" className="remove">
      <div className="logo">
        <Lottie
          animationData={animation}
          width={200}
          height={200}
          loop={false}
        />
      </div>
    </div>
  )
}

export default Preloader
