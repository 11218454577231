import { useState } from "react";

import { useLocaleContext } from "hooks/useLocaleContext";

import { Header } from "layouts/Holder/components/Header";
import { Menu } from "layouts/Holder/components/Menu";
import { Breadcrumb } from "components/general/Breadcrumb";
import { EditForm } from "./components/EditForm";

import styles from "./Profile.module.scss"

export function Profile() {
  const { localeData } = useLocaleContext()
  const [isMenuMobileExpanded, setIsMenuMobileExpanded] = useState(false)
  
  return (
    <div className={styles.profileArea}>
      <Header
        isMenuMobileExpanded={isMenuMobileExpanded}
        setIsMenuMobileExpanded={setIsMenuMobileExpanded}
      />

      <Menu
        isMenuMobileExpanded={isMenuMobileExpanded}
      />

      <main>
        <Breadcrumb />

        <h1>{localeData.holderBreadcrumb.paths.profile}</h1>

        <div className={styles.content}>
          <EditForm
            localeData={localeData}
          />
        </div>
      </main>
    </div>
  )
}