export function transformFlipflopSize(size: number | undefined) {
  if(!size) return null;

  const sizeString = String(size)
  const start = sizeString.slice(0,1)
  const end = sizeString.slice(2)

  const size1 = sizeString.slice(0, 2)
  const size2 = end === "0" ? `${+start+1}${end}` :  `${start}${end}`

  return `${size1}/${size2}`
}