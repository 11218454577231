import Lottie from 'lottie-react'
import animation from './animation.json'
import './CloudsStyle.scss'

const Clouds = () => {
  return (
    <div id="main-clouds">
      <Lottie animationData={animation} width={100} height={100} loop={true} />
    </div>
  )
}

export default Clouds
