import { Link } from "react-router-dom";
import Hamburger from 'hamburger-react'

import { useLocaleContext } from "hooks/useLocaleContext";

import Logo from 'assets/vectors/logo-new-font.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/language-arrow-down.svg'

import styles from "./Header.module.scss";

interface HeaderProps {
  isMenuMobileExpanded: boolean
  setIsMenuMobileExpanded: React.Dispatch<React.SetStateAction<boolean>> 
}

export function Header({ isMenuMobileExpanded, setIsMenuMobileExpanded }: HeaderProps) {
  const { selectedLanguage, onChangeLanguage } = useLocaleContext()

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <Link to="/">
          <img src={Logo} alt="NFT Havaianas" />
        </Link>

        <div className={styles.language}>
          <button
            type="button"
            title="Selecionar idioma"
            aria-label="Selecionar idioma"
          >
            <span>{selectedLanguage}</span>
            <ArrowDown />
          </button>

          <div className={styles.option}>
            <button
              type="button"
              title="Selecionar idioma"
              aria-label="Selecionar idioma"
              onClick={onChangeLanguage}
            >
              <span>{selectedLanguage === 'en' ? 'PT' : 'EN'}</span>
            </button>
          </div>
        </div>

        <div className={styles.menuMobile}>
          <Hamburger
            color="#FEFEFE"
            toggled={isMenuMobileExpanded}
            toggle={setIsMenuMobileExpanded}
          />
        </div>
      </div>
    </header>
  )
}