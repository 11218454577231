import { useLocaleContext } from "hooks/useLocaleContext"
import styles from "./Tracking.module.scss"

export function Tracking() {
  const { localeData } = useLocaleContext()

  const isDelivered = false
  // Mock
  const trackCod = "BR221788609974B"

  return (
    <>
      {isDelivered ? (
        <div className={styles.tracking}>
          <div className={styles.carrying}>
            <span>{localeData.claimDetails.tracking.carrying} {trackCod}</span>
          </div>

          <a href="http://" target="_blank" rel="noopener noreferrer">{localeData.claimDetails.tracking.button}</a>
        </div>
      ) : (
        <span className={styles.trackingText}>{localeData.claimDetails.tracking.text}</span>
      )}
    </>
  )
}