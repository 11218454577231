import { useCallback, useEffect, useState, useContext } from "react"

import avatarImg from "assets/images/avatar-holder-profile.png"

import { EditModal } from "../EditModal";

import { LocaleData } from "types/language";
import { api } from "services/api"
import { UserContext } from 'contexts/UserContext';

import styles from "./ImagePreview.module.scss"
import { Toast } from "components/general/Toast";
import { NftData } from "types/apiTypes";

interface ImagePreviewProps {
  localeData: LocaleData
}

export function ImagePreview({ localeData }: ImagePreviewProps) {
  const { profileImage } =  useContext(UserContext)

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [toastVisibility, setToastVisibility] = useState<"visible" | "hidden">("hidden")
  const [toastInfo, setToastInfo] = useState({
    text: "",
    isError: false
  })

  function handleToastVisibility(text: string, isError: boolean) {
    setToastVisibility("visible")

    setToastInfo({
      text,
      isError
    })
  }

  const [nftList, setNftList] = useState<NftData[]>([])

  function openEditModal() {
    setIsEditModalOpen(true)
  }

  function closeEditModal() {
    setIsEditModalOpen(false)
  }

  const getNFTList = useCallback(async () => {
    try {
      const { data } = await api.get("nfth")

      setNftList(data);
    } catch (error) {
      setNftList([])
    }
  }, [])

  useEffect(() => {
    getNFTList()
  }, [getNFTList])

  const imageUrl = profileImage.length > 0 ? profileImage : avatarImg

  return (
    <div className={styles.imagePreview}>
      <button type="button" onClick={openEditModal}>
        <img src={imageUrl} alt="Profile" />
      </button>

      <span>
        {localeData.profile.imageProfile}
      </span>

      {isEditModalOpen && (
        <EditModal
          onCloseModal={closeEditModal}
          showToast={handleToastVisibility}
          localeData={localeData}
          nftList={nftList}
        />
      )}

      <Toast 
        setVisibility={setToastVisibility}
        visibility={toastVisibility}
        text={toastInfo.text}
        isError={toastInfo.isError}
      />
    </div>
  )
}