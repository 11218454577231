export const KEY_TOKEN = "token@holder-nfth"
export const KEY_ROLE = "role@holder-nfth"
export const PROJECT_ID = '64728bc4ba3f0511e7fd87c897bfa970'

export const FLIPFLOP_CHECKOUT_DATA = {
  address: {
    number: "715",
    zipCode: "30180100",
    state: "MG",
    city: "Belo Horizonte",
    district: "Centro",
    address: "Rua dos Guajajaras",
    complement: "6º, 8º, 9º e 10º andar"
  },
  userNewValues: {
    firstName: "Matheus Osvaldo Perianez",
    lastName: "Jorente",
    email: "matheuso@alpargatas.com",
    countryRegistration: "41493186892",
    phoneNumber: "19997101848",
    userType: "user",
    agreeTerm: true,
    birthDate: "1994-08-05"
  }
}

export const CLAIM_START_DATE = "2023-03-13T09:00:00"
export const CLAIM_EXPIRES_DATE = "2023-03-14T12:00:00"

export const SIZE_VALUES_CM = [
  ["60", "72", "63"],
  ["62", "75", "64"],
  ["64", "78", "65"],
  ["66", "81", "66"],
]

export const SIZE_VALUES_IN = [
  ["23.6", "28.3", "24.8"],
  ["24.4", "29.5"," 25.2"],
  ["25.2", '30.7', "25.6"],
  ['26', "31.9", "26"],
]
