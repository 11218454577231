import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from 'components/pages/home/index'

import { HolderArea } from '../layouts/Holder'

import { Inventory } from '../pages/Inventory'
import { Profile } from 'pages/Profile'
import { Benefits } from 'pages/Benefits'
import { MyClaims } from 'pages/MyClaims'
import { ClaimDetails } from 'pages/ClaimDetails'

import { PrivateRoute } from './PrivateRoute'

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/holder" element={<PrivateRoute><HolderArea /></PrivateRoute>}>
          <Route path="inventory" element={<Inventory />} />
          <Route path="benefits" element={<Benefits />} />
          <Route path="my-claims" element={<MyClaims />} />
        </Route>

        <Route path='/holder/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/holder/my-claims/:id" element={<PrivateRoute><ClaimDetails /></PrivateRoute>} />
      </Routes>
    </BrowserRouter>
  )
}
