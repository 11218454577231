import {
  createContext,
  ReactNode,
  useState,
  useCallback
} from 'react'

import { DeliveryFormData } from 'pages/Delivery'

import { Flipflop, Hoodie, KitItem, SelectedNft } from 'types/claimTypes'
import { transformFlipflopSize } from 'utils/transformFlipflopSize'

interface ClaimContextType {
  kit: KitItem[]
  hoodie: Hoodie | null
  flipflop: Flipflop | null
  deliveryInfo: DeliveryFormData | null
  selectedNft: SelectedNft | null
  addHoodieToKit: (hoodie: Hoodie) => void
  addFlipflopToKit: (flipflop: Flipflop) => void
  saveDeliveryInfo: (data: DeliveryFormData) => void
  selectNftToBurn: (nft: SelectedNft) => void
  clearData: () => void
}

interface ClaimContextProviderProps {
  children: ReactNode
}

export const ClaimContext = createContext({} as ClaimContextType)

export function ClaimContextProvider({ children }: ClaimContextProviderProps) {
  const [kit, setKit] = useState<KitItem[]>([])
  const [hoodie, setHoodie] = useState<Hoodie | null>(null)
  const [flipflop, setFlipflop] = useState<Flipflop | null>(null)
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryFormData | null>(null)
  const [selectedNft, setSelectedNft] = useState<SelectedNft | null>(null)

  function addHoodieToKit(hoodie: Hoodie) {
    setHoodie(hoodie);
    
    const newKitItem: KitItem = {
      type: "sweatshirt",
      size: hoodie.size,
      print: hoodie.pattern,
      color: "",
    }

    setKit(oldState => [...oldState, newKitItem])
  }

  function addFlipflopToKit(flipflop: Flipflop) {
    setFlipflop(flipflop)

    const flipflopSize = transformFlipflopSize(flipflop.shoeSize)

    const newKitItem: KitItem = {
      type: "slipper",
      size: flipflopSize || "",
      print: flipflop?.printName || "",
      color: flipflop.stripColorName,
      benefitImage: flipflop?.printImagePath || ""
    }

    setKit(oldState => [...oldState, newKitItem])
  }

  function saveDeliveryInfo(data: DeliveryFormData) {
    setDeliveryInfo(data);
  }

  function clearData() {
    setKit([])
    setHoodie(null)
    setFlipflop(null)
    setDeliveryInfo(null)
    setSelectedNft(null)
  }

  const selectNftToBurn = useCallback((nft: SelectedNft) => {
    setSelectedNft(nft)
  }, [])
  
  return (
    <ClaimContext.Provider
      value={{
        kit,
        hoodie,
        flipflop,
        deliveryInfo,
        selectedNft,
        addHoodieToKit,
        addFlipflopToKit,
        saveDeliveryInfo,
        selectNftToBurn,
        clearData
      }}
    >
      {children}
    </ClaimContext.Provider>
  )
}