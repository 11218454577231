import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

import { Header } from "layouts/Holder/components/Header";
import { Menu } from "layouts/Holder/components/Menu";
import { Breadcrumb } from "components/general/Breadcrumb";

import { Shipping } from "./components/Shipping";
import { Claim } from "./components/Claim";
import { Tracking } from "./components/Tracking";

import { useLocaleContext } from "hooks/useLocaleContext";
import { useUserContext } from "hooks/useUserContext";

import styles from "./ClaimDetails.module.scss"

export function ClaimDetails() {
  const { localeData } = useLocaleContext()
  const { claims } = useUserContext()
  const { id } = useParams()
  const navigate = useNavigate()

  const [isMenuMobileExpanded, setIsMenuMobileExpanded] = useState(false)
  const supportLink = "https://discord.com/channels/1008715964558344242/1009525282862010509/1029383580285149234"

  if(!id) {
    navigate("/holder/my-claims")
  }

  const selectedClaim = claims.find(claim => claim.kit.redemptionNumber === Number(id))

  if(!selectedClaim) {
    navigate("/holder/my-claims")
  }
  
  return (
    <div className={styles.claimDetails}>
      <Header
        isMenuMobileExpanded={isMenuMobileExpanded}
        setIsMenuMobileExpanded={setIsMenuMobileExpanded}
      />

      <Menu
        isMenuMobileExpanded={isMenuMobileExpanded}
      />

      <main>
        <Breadcrumb />

        {selectedClaim && (
          <div className={styles.content}>
            <h2>{localeData.claimDetails.title}</h2>

            <div className={styles.details}>
              <div className={styles.detailsBox}>
                <h3>{localeData.claimDetails.shipping.title}</h3>

                <Shipping checkout={selectedClaim.kit.checkout} />
              </div>
              

              <div className={styles.detailsBox}>
                <h3>{localeData.claimDetails.claim.title}</h3>

                <Claim kit={selectedClaim.kit} tokenId={selectedClaim.tokenId} />
              </div>

              <div className={styles.detailsBox}>
                <h3>{localeData.claimDetails.tracking.title}</h3>

                <Tracking />
              </div>

              <div className={styles.help}>
                <span>{localeData.claimDetails.help.text} <a href={supportLink} target="_blank" rel="noopener noreferrer">{localeData.claimDetails.help.link}</a></span>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  )
}