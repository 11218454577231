import { ReactComponent as CloseIcon } from 'assets/vectors/icon-close.svg'
import './ModalStyle.scss'

const Modal = ({ children, title, isOpen, setIsOpen, ...props }) => {
  return (
    isOpen && (
      <div id="container">
        <div className="modal">
          <div className="header">
            <p>{title}</p>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="close-button"
            >
              <CloseIcon />
            </button>
          </div>
          {children}
        </div>
      </div>
    )
  )
}

export default Modal
