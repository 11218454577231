import { useContext } from "react"

import { UserContext } from "contexts/UserContext"

export function useUserContext() {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('You have to use UserContextProvider in your App')
  }
    
  return context;
}