import './SectionStyle.scss'

import { ReactComponent as SocialInstagram } from 'assets/vectors/social-instagram.svg'
import { ReactComponent as SocialTwitter } from 'assets/vectors/social-twitter.svg'

import LogoHavaImage2 from 'assets/images/footer-logo-havaianas.png'
import LogoIoasysImage from 'assets/images/footer-logo-ioasys.png'
import LogoHavaImage from 'assets/images/footer-logo-red.png'
import LogoImage from 'assets/images/footer-logo.png'

const Section = ({ data }) => {
  const goToSection = (name) => {
    const section = document.querySelector(`.section-${name}`)

    if (name === 'flakkies') {
      const top = section.offsetTop - window.innerHeight / 2

      window.scrollTo({
        top: top,
        behavior: 'smooth',
      })
    } else {
      const top = section.offsetTop

      window.scrollTo({
        top: top,
        behavior: 'smooth',
      })
    }
  }

  return (
    <section className="section section-contact">
      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div className="left-logo appear-fade" appear="0.3">
              <img src={LogoImage} alt="Imagem" />
            </div>

            <div className="left-social appear-fade" appear="0.3">
              <ul>
                <li>
                  <a
                    href="https://twitter.com/havaianasbr"
                    title="Ir para o Twitter"
                    aria-label="Ir para o Twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialTwitter />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/havaianas/"
                    title="Ir para o Instagram"
                    aria-label="Ir para o Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialInstagram />
                  </a>
                </li>

              </ul>
            </div>
          </div>

          <div className="center appear-fade" appear="0.3">
            <div className="center-logo">
              <img src={LogoHavaImage} alt="Imagem" />
            </div>
          </div>

          <div className="right appear-fade" appear="0.3">
            <div className="right-navigation">
              <ul>
                <li>
                  <button
                    type="button"
                    title="Ir para a seção"
                    aria-label="Ir para a seção"
                    onClick={() => goToSection('flakkies')}
                  >
                    {data.header.textButton1}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex-wrapper">
          <div className="left">
            <div className="left-logos appear-fade" appear="0.3">
              <a
                href="https://havaianas.com.br/"
                title="Ir para o site"
                aria-label="Ir para o site"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LogoHavaImage2} alt="Imagem" />
              </a>

              <span>+</span>

              <a
                href="https://ioasys.com.br/"
                title="Ir para o site"
                aria-label="Ir para o site"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LogoIoasysImage} alt="Imagem" />
              </a>
            </div>
          </div>

          <div className="right appear-fade" appear="0.3">
            <div className="right-copyright">
              Copyright © 2022. All rights reserved
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
