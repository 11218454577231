import { useContext } from "react"

import { LocaleContext } from "contexts/LocaleContext"

export function useLocaleContext() {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error('You have to use LocaleContextProvider in your App')
  }
    
  return context;
}