import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useAccount } from 'wagmi'
import axios from 'axios'

import { api } from 'services/api'
import { Claim } from 'types/apiTypes'

interface User {
  id: string
  walletId: string
  name: string | null
  profileImage: Buffer
}

interface UserContextType {
  name: string | null
  profileImage: string
  claims: Claim[]
  invalidateData: () => void
}

interface UserContextProviderProps {
  children: ReactNode
}

export const UserContext = createContext({} as UserContextType)

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [claims, setClaims] = useState<Claim[]>([]);

  const { address } = useAccount()

  function invalidateData() {
    getUser()
  }

  const getUser = useCallback(async () => {
    try {
      const { data } = await api.get<User>("users")
      setUser(data)

    } catch (error) {
      setUser(null)
    }
  }, [])

  const getClaims = useCallback(async () => {
    try {
      const { data } = await api.get<Claim[]>("users/kits")

      setClaims(data)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        setClaims([])
      }
    }
  }, [])

  useEffect(() => {
    if(address) {
      getUser()
    }
  }, [address, getUser])

  useEffect(() => {
    if(user) {
      getClaims()
    }
  }, [user, getClaims])

  function toBase64(arr: Buffer) {
    return Buffer.from(arr).toString('base64');
  }
  
  const base64String = user?.profileImage ? toBase64(user.profileImage) : null
  const imageUrl = base64String ? `data:image/png;base64,${base64String}` : ""

  const username = user?.name ?? null
  
  return (
    <UserContext.Provider
      value={{
        name: username,
        profileImage: imageUrl,
        claims,
        invalidateData
      }}
    >
      {children}
    </UserContext.Provider>
  )
}