import { useLayoutEffect, useState } from "react"
import { Outlet } from 'react-router-dom'

import { Header } from './components/Header'
import { Menu } from './components/Menu'

import styles from "./Holder.module.scss"

export function HolderArea() {
  const [isMenuMobileExpanded, setIsMenuMobileExpanded] = useState(false)

  useLayoutEffect(() => {
    const body = document.querySelector("body")
    body?.removeAttribute("style")
  }, [])

  return (
    <div className={styles.holderArea}>
      <Header 
        isMenuMobileExpanded={isMenuMobileExpanded}
        setIsMenuMobileExpanded={setIsMenuMobileExpanded}
      />
      
      <main>
        <Menu
          isMenuMobileExpanded={isMenuMobileExpanded}
        />

        <Outlet />
      </main>
    </div>
  )
}