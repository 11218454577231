import { useState, useEffect } from 'react'
import Hamburger from 'hamburger-react'
import { useNavigate } from 'react-router-dom'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount } from 'wagmi'
import { isMobile } from 'react-device-detect'

import { ReactComponent as ArrowDown } from 'assets/vectors/language-arrow-down.svg'
import { ReactComponent as LogoNew } from 'assets/vectors/logo-new-font.svg'
import modalError from 'assets/vectors/modal-error.svg'

import Button from '../Button'
import Modal from '../Modal'

import { useAuthContext } from 'hooks/useAuthContext'

import './HeaderStyle.scss'

const Header = ({ data, language, setLanguage }) => {
  let navigate = useNavigate()
  const { authenticated, handleLogin, role } = useAuthContext()

  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isConnected } = useAccount()

  const { open } = useWeb3Modal()

  const goToSection = (name) => {
    if (isNavExpanded) {
      setIsNavExpanded(false)
    }
    if (window.location.pathname === '/mint') {
      navigate('/')
    }
    const section = document.querySelector(`.section-${name}`)

    if (name === 'flakkies') {
      const top = section.offsetTop - window.innerHeight / 2

      window.scrollTo({
        top: top,
        behavior: 'smooth',
      })
    } else {
      const top = section.offsetTop

      window.scrollTo({
        top: top,
        behavior: 'smooth',
      })
    }
  }

  function openHolderArea() {
    if (isConnected && authenticated) {
      if (role.isHolder) {
        navigate('/holder/inventory')
      } else {
        navigate('/holder/benefits')
      }
    }

    if (isConnected && !authenticated) {
      handleLogin(
        (url) => navigate(url),
        () => setIsModalOpen(true),
      )
    }

    if (!isConnected) {
      open()
    }
  }

  useEffect(() => {
    if (isConnected) {
      handleLogin(
        (url) => navigate(url),
        () => setIsModalOpen(true),
      )
    }
  }, [isConnected, handleLogin, navigate])

  return (
    <header
      id="main-header"
      className={isNavExpanded ? 'header expanded' : 'header'}
    >
      <div className="container">
        <div className="flex-wrapper">
          <LogoNew
            style={{ cursor: 'pointer', pointerEvents: 'auto' }}
            onClick={() => navigate('/')}
          />
          <button
            type="button"
            title="button"
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded)
            }}
          >
            <Hamburger
              color="#d2d2d2"
              toggled={isNavExpanded}
              toggle={setIsNavExpanded}
            />
          </button>
          <div className="right">
            <div
              className={isNavExpanded ? 'navigation expanded' : 'navigation'}
            >
              <ul>
                <li>
                  <button
                    type="button"
                    title="Ir para a seção"
                    aria-label="Ir para a seção"
                    onClick={() => goToSection('flakkies')}
                  >
                    {data.header.textButton1}
                  </button>
                  <div className="line"> </div>
                </li>

                {isMobile && isNavExpanded && (
                  <li
                    onClick={() => {
                      setLanguage(language === 'pt' ? 'en' : 'pt')
                    }}
                  >
                    <p>{data.languageMobile}</p>
                  </li>
                )}

                {isNavExpanded && (
                  <li className="button-holder">
                    <Button onClick={openHolderArea}>
                      {data.header.textButtonHolder}
                    </Button>
                  </li>
                )}
              </ul>
            </div>

            <Button onClick={openHolderArea} className="button-holder">
              {data.header.textButtonHolder}
            </Button>

            {!isMobile && language === 'pt' && (
              <div className="language">
                <button
                  type="button"
                  title="Selecionar idioma"
                  aria-label="Selecionar idioma"
                >
                  <span>PT</span>
                  <ArrowDown />
                </button>

                <div className="option">
                  <button
                    type="button"
                    title="Selecionar idioma"
                    aria-label="Selecionar idioma"
                    onClick={() => setLanguage('en')}
                  >
                    <span>EN</span>
                  </button>
                </div>
              </div>
            )}

            {!isMobile && language === 'en' && (
              <div className="language">
                <button
                  type="button"
                  title="Selecionar idioma"
                  aria-label="Selecionar idioma"
                >
                  <span>EN</span>
                  <ArrowDown />
                </button>

                <div className="option">
                  <button
                    type="button"
                    title="Selecionar idioma"
                    aria-label="Selecionar idioma"
                    onClick={() => setLanguage('pt')}
                  >
                    <span>PT</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <div className="modal-content">
          <img src={modalError} alt="Erro" />

          <div className="error-info">
            <h2>{data.header.modalNFThError.title}</h2>
            <p>{data.header.modalNFThError.text}</p>
          </div>

          <button onClick={() => setIsModalOpen(false)}>
            {data.header.modalNFThError.buttonText}
          </button>
        </div>
      </Modal>
    </header>
  )
}

export default Header
