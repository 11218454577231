import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

const ScrollPage = () => {
  useEffect(() => {
    const body = document.querySelector('body')
    const page = body.querySelector('main.page')
    const flakkies = document.querySelector('#main-flakkies')
    const flakkiesItems = flakkies.querySelectorAll('.item')
    const planet = document.querySelector('#main-planet')
    // const header = document.querySelector('#main-header')

    let current = 0
    let target = 0
    let ease = isMobile ? 0.08 : 0.02

    function setupHeight() {
      const height = page.offsetHeight

      body.style.height = height + 'px'
    }

    function setupLerp(start, end, t) {
      return start * (1 - t) + end * t
    }

    function setTransform(el, transform) {
      el.style.transform = transform
    }

    function setupParallax() {
      const parallaxElements = document.querySelectorAll('[parallax]')

      for (let i = 0; i < parallaxElements.length; i++) {
        const parallax = parallaxElements[i]
        const positive = parallax.hasAttribute('positive')
        const options = parallax.getBoundingClientRect()
        const percentage = (options.top / window.innerHeight) * 100
        const effect = Number(parallax.getAttribute('parallax'))

        if (percentage > -100 && percentage < 100) {
          let value = -Math.abs((percentage - 100) * effect)

          if (positive) value = Math.abs((percentage - 100) * effect)

          setTransform(parallax, `translateX(${value}%)`)
        }
      }
    }

    function setupPlanet() {
      const section = page.querySelector('.section-galaxy')
      const options = section.getBoundingClientRect()
      const percentage = (options.top / window.innerHeight) * 100

      if (percentage >= 100 || percentage <= -160) {
        setTransform(planet, `scale(0) translate(-50%, 50%)`)

        return
      }

      if (percentage > 0 && percentage < 100) {
        const scale = Math.abs(percentage / 100 - 1)

        if (!planet.classList.contains('first')) {
          planet.classList.add('first')
          planet.classList.remove('second')
        }

        setTransform(planet, `scale(${scale}) translate(-50%, 50%)`)
      }

      if (percentage > -160 && percentage < 0) {
        const scale = 1 - Math.abs(percentage / 100) + 0.8

        if (scale >= 1) return

        if (!planet.classList.contains('second')) {
          planet.classList.add('second')
          planet.classList.remove('first')
        }

        setTransform(planet, `scale(${scale}) translate(-50%, 50%)`)
      }
    }

    function setupFlakkies() {
      const section = page.querySelector('.section-flakkies')
      const options = section.getBoundingClientRect()
      const percentage = (options.top / window.innerHeight) * 100

      if (percentage >= 100 || percentage <= -460) {
        if (flakkies.classList.contains('active')) {
          flakkies.classList.remove('active')
        }

        return
      } else {
        if (!flakkies.classList.contains('active')) {
          flakkies.classList.add('active')
        }
      }

      if (percentage > 0 && percentage < 100) {
        const transform = -Math.abs(percentage - 100) * 2

        setTransform(flakkiesItems[0], `translateX(${transform}%)`)
      }

      if (percentage > -100 && percentage < 0) {
        const transform = -Math.abs(percentage) * 2

        setTransform(flakkiesItems[1], `translateX(${transform}%)`)
      }

      if (percentage > -200 && percentage < -100) {
        const transform = -Math.abs(percentage + 100) * 2

        setTransform(flakkiesItems[2], `translateX(${transform}%)`)
      }

      if (percentage > -300 && percentage < -200) {
        const transform = -Math.abs(percentage + 200)

        setTransform(flakkiesItems[3], `translateX(${transform}%)`)
      }

      if (percentage > -460 && percentage < -300) {
        const transform = -Math.abs(percentage + 300)

        setTransform(flakkies, `translateY(${transform}%)`)
      }

      // if (percentage > -160 && percentage < 0) {
      //   const scale = 1 - Math.abs(percentage / 100) + 0.8

      //   if (scale >= 1) return

      //   if (!planet.classList.contains('second')) {
      //     planet.classList.add('second')
      //     planet.classList.remove('first')
      //   }

      //   setTransform(planet, `scale(${scale}) translate(-50%, 50%)`)
      // }
    }

    function setupAnimation() {
      if (!body.classList.contains('not-scrollable')) {
        current = parseFloat(setupLerp(current, target, ease)).toFixed(2)
        target = window.scrollY

        setupParallax()
        setupPlanet()
        setupFlakkies()
        setTransform(page, `translateY(-${current}px)`)

        // const scrollTop = window.scrollY
        // const docHeight = body.offsetHeight
        // const winHeight = window.innerHeight
        // const scrollPercent = scrollTop / (docHeight - winHeight)
      }

      requestAnimationFrame(setupAnimation)
    }

    window.addEventListener('resize', () => setupHeight())

    body.addEventListener('finishedPreloader', function () {
      body.dispatchEvent(new Event('pageChanged'))

      setTimeout(() => {
        body.classList.remove('not-scrollable')
        setupHeight()
        setupAnimation()
      }, 1500)

      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // })
    })
  }, [])

  return false
}

export default ScrollPage
