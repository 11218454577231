import { createContext, ReactNode, useState } from "react";

import languageEN from 'utils/language-en.json'
import languagePT from 'utils/language-pt.json'

import { LocaleData } from "types/language";

interface LocaleContextType {
  localeData: LocaleData,
  selectedLanguage: string
  onChangeLanguage: () => void
}

interface LocaleContextProviderProps {
  children: ReactNode
}

export const LocaleContext = createContext({} as LocaleContextType)

export function LocaleContextProvider({
  children,
}: LocaleContextProviderProps) {
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const navigatorLanguage = window.navigator.language

    const language = navigatorLanguage !== 'pt-BR' ? 'en' : 'pt'

    return language;
  })

  function onChangeLanguage() {
    if(selectedLanguage === 'en') {
      setSelectedLanguage('pt')
    } else {
      setSelectedLanguage('en')
    }
  }

  const localeData = selectedLanguage === 'pt' ? languagePT : languageEN
  
  return (
    <LocaleContext.Provider
      value={{
        localeData,
        selectedLanguage,
        onChangeLanguage
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}